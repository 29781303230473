import React from 'react'
import Layout from "../components/layout";
import {navigate} from "gatsby"
import { render } from 'react-dom';
import checkmark from '../images/checkmark.png';


export default class ContactComplete extends React.Component {

    backtoHome(){
        console.log("Back to home")
        navigate("/")
    }

    render(){
        return (
            <Layout>
                <div className="w-screen h-screen max-w-xl m-6 p-4 mx-auto flex flex-col text-black items-center text-center py-32">
                    <img className="w-32 h-32 flex" src={checkmark}></img>
                    <div className="text-3xl mt-4">Thank You.</div>
                    <div className="mt-2"><b>We will contact you back as soon as possible.</b></div>
                    <button onClick={this.backtoHome}
                            className="mx-auto lg:mx-0 buttonHover mt-4 bg-blue-500 text-gray-800 font-bold rounded-full my-2 py-2 px-6 shadow-lg">
                        <div className="flex flex-row">
                            <div className="text-lg">
                            Back to home page
                            </div>
                        </div>
                    </button>
                </div>
            </Layout>
        )
    }   
    
}
